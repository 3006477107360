import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Headerbar.css';

class Headerbar extends React.Component {
    render() 
    { 
      return (
        <div>
          <div className='Headerbar'>

            <Dropdown className='Dropdown_Header'>
              <Dropdown.Toggle variant="standard" id="dropdown-basic">
                <svg className='HeaderMenu-Icon' fill="var(--SexyOrange)" width="64" height="64" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M0.844 6.050c-0.256-0.256-0.381-0.581-0.381-0.975s0.125-0.719 0.381-0.975 0.581-0.381 0.975-0.381h28.512c0.394 0 0.719 0.125 0.975 0.381s0.381 0.581 0.381 0.975-0.125 0.719-0.381 0.975-0.581 0.381-0.975 0.381h-28.512c-0.394 0-0.719-0.125-0.975-0.381zM31.306 14.963c0.256 0.256 0.381 0.581 0.381 0.975s-0.125 0.719-0.381 0.975-0.581 0.381-0.975 0.381h-28.512c-0.394 0-0.719-0.125-0.975-0.381s-0.381-0.581-0.381-0.975 0.125-0.719 0.381-0.975 0.581-0.381 0.975-0.381h28.512c0.394 0 0.719 0.125 0.975 0.381zM31.306 25.819c0.256 0.256 0.381 0.581 0.381 0.975s-0.125 0.719-0.381 0.975-0.581 0.381-0.975 0.381h-28.512c-0.394 0-0.719-0.125-0.975-0.381s-0.381-0.581-0.381-0.975 0.125-0.719 0.381-0.975 0.581-0.381 0.975-0.381h28.512c0.394 0 0.719 0.131 0.975 0.381z"></path></svg>
              </Dropdown.Toggle>
        
              <Dropdown.Menu>
                <Dropdown.Item href="/">Home</Dropdown.Item>
                <Dropdown.Item href="/Download">Download</Dropdown.Item>

                <Dropdown.Item href="https://www.instagram.com/calamityatstarbase349/">
                  <svg className='HeaderMenu-Icon' fill="#D66F00" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="m128 80a48 48 0 1 0 48 48 48.05436 48.05436 0 0 0 -48-48zm0 80a32 32 0 1 1 32-32 32.03635 32.03635 0 0 1 -32 32zm44-132h-88a56.06353 56.06353 0 0 0 -56 56v88a56.06353 56.06353 0 0 0 56 56h88a56.06353 56.06353 0 0 0 56-56v-88a56.06353 56.06353 0 0 0 -56-56zm40 144a40.04521 40.04521 0 0 1 -40 40h-88a40.04521 40.04521 0 0 1 -40-40v-88a40.04521 40.04521 0 0 1 40-40h88a40.04521 40.04521 0 0 1 40 40zm-20-96a12 12 0 1 1 -12-12 12 12 0 0 1 12 12z"/></svg>
                </Dropdown.Item>

                <Dropdown.Item href="https://twitter.com/Starbase349">
                  <svg className='HeaderMenu-Icon' data-name="Layer 1" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M86.94,30.66a19.58,19.58,0,0,1,14,6.17s7.64-1.5,11.86-4.47c0,0-1.06,5.88-8.09,10.38h.06c.54,0,4.48-.1,10.68-3,0,0-1.19,3.39-9.3,9.84v0c.49,15.26-7.06,43.47-35,53.51A56.36,56.36,0,0,1,52,106.52,53.69,53.69,0,0,1,22.5,97.85C24,98,25.35,98,26.7,98c16.52,0,24.52-8.48,24.08-8.48h-.61c-14,0-17.41-13.4-17.41-13.4a6.46,6.46,0,0,0,3.73.92,20.4,20.4,0,0,0,5-.73c-15.8-3.74-15.21-18.9-15.21-18.9a13.45,13.45,0,0,0,7.2,2.26,7,7,0,0,0,1.26-.1C20.31,48.15,29,34.52,29,34.52c16.26,18.87,36.25,19.54,39,19.54h.33c-1.48-8.86,2.17-17.37,12-22a15.35,15.35,0,0,1,6.54-1.4M106.2,49.6v0M122,18l-12.93,9.08a23.93,23.93,0,0,1-6.34,2.65,26.16,26.16,0,0,0-15.83-5.54h0a21.69,21.69,0,0,0-9.3,2c-9.18,4.31-14.76,11.75-15.87,20.68A49.25,49.25,0,0,1,33.94,30.28l-5.7-6.61L23.54,31a25.19,25.19,0,0,0-3.4,16.66l-.37,9.53a26.24,26.24,0,0,0,5.69,16.68l1,3.9a26.17,26.17,0,0,0,8.36,12.89,37.24,37.24,0,0,1-8.13.86c-1.19,0-2.42-.05-3.66-.15L.1,89.46l18.55,13.63A60.21,60.21,0,0,0,52,113a63,63,0,0,0,21.38-3.8,57.06,57.06,0,0,0,31.7-27.91,68.92,68.92,0,0,0,7.57-28.65c6.63-5.71,8.45-9.35,8.94-10.74l5.12-14.57-7,3.26L122,18Z"/></svg>     
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
    

          <div className="Headerbar-Pages">   
   
            <div className="Headerbar-Btn"> <a href="/">Home</a> </div>
            <div className="Headerbar-Btn"> <a href="/Download">Download</a> </div>


          <div className='Headerbar-Socials'>
            <a href="https://www.instagram.com/calamityatstarbase349/"> 
              <svg className='Icon-Socials' fill="#D66F00" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="m128 80a48 48 0 1 0 48 48 48.05436 48.05436 0 0 0 -48-48zm0 80a32 32 0 1 1 32-32 32.03635 32.03635 0 0 1 -32 32zm44-132h-88a56.06353 56.06353 0 0 0 -56 56v88a56.06353 56.06353 0 0 0 56 56h88a56.06353 56.06353 0 0 0 56-56v-88a56.06353 56.06353 0 0 0 -56-56zm40 144a40.04521 40.04521 0 0 1 -40 40h-88a40.04521 40.04521 0 0 1 -40-40v-88a40.04521 40.04521 0 0 1 40-40h88a40.04521 40.04521 0 0 1 40 40zm-20-96a12 12 0 1 1 -12-12 12 12 0 0 1 12 12z"/></svg>
            </a>
            <a href="https://twitter.com/Starbase349"> 
              <svg className='Icon-Socials' data-name="Layer 1" id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M86.94,30.66a19.58,19.58,0,0,1,14,6.17s7.64-1.5,11.86-4.47c0,0-1.06,5.88-8.09,10.38h.06c.54,0,4.48-.1,10.68-3,0,0-1.19,3.39-9.3,9.84v0c.49,15.26-7.06,43.47-35,53.51A56.36,56.36,0,0,1,52,106.52,53.69,53.69,0,0,1,22.5,97.85C24,98,25.35,98,26.7,98c16.52,0,24.52-8.48,24.08-8.48h-.61c-14,0-17.41-13.4-17.41-13.4a6.46,6.46,0,0,0,3.73.92,20.4,20.4,0,0,0,5-.73c-15.8-3.74-15.21-18.9-15.21-18.9a13.45,13.45,0,0,0,7.2,2.26,7,7,0,0,0,1.26-.1C20.31,48.15,29,34.52,29,34.52c16.26,18.87,36.25,19.54,39,19.54h.33c-1.48-8.86,2.17-17.37,12-22a15.35,15.35,0,0,1,6.54-1.4M106.2,49.6v0M122,18l-12.93,9.08a23.93,23.93,0,0,1-6.34,2.65,26.16,26.16,0,0,0-15.83-5.54h0a21.69,21.69,0,0,0-9.3,2c-9.18,4.31-14.76,11.75-15.87,20.68A49.25,49.25,0,0,1,33.94,30.28l-5.7-6.61L23.54,31a25.19,25.19,0,0,0-3.4,16.66l-.37,9.53a26.24,26.24,0,0,0,5.69,16.68l1,3.9a26.17,26.17,0,0,0,8.36,12.89,37.24,37.24,0,0,1-8.13.86c-1.19,0-2.42-.05-3.66-.15L.1,89.46l18.55,13.63A60.21,60.21,0,0,0,52,113a63,63,0,0,0,21.38-3.8,57.06,57.06,0,0,0,31.7-27.91,68.92,68.92,0,0,0,7.57-28.65c6.63-5.71,8.45-9.35,8.94-10.74l5.12-14.57-7,3.26L122,18Z"/></svg>     
            </a>
          </div>
        </div> 

        </div>
        <div className='Gradient-TopScreen'/> 
        </div>
      )
    }
};

export default Headerbar

//