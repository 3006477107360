import React from "react";

import './Button_SubPage.css';

interface Button_SubPage_Props {
    PageName: string;
    FlavourImage: string;
    Route: string;
}

function Button_Subpage(props:Button_SubPage_Props) {
    return (

            <div className='Button_Subpage_Background'>
                <a href={props.Route}/>
                <div className='Button_Subpage_Header'>{props.PageName}</div>
                <img src={props.FlavourImage} alt={props.FlavourImage}/>
            </div>

    );
}
export default Button_Subpage;