import React from "react";

import './Button_Download.css';

interface TextBox_Download_Props {
    Text: string;
    Link: string;
    FlavourImage: string;
}

function Button_Download(props: TextBox_Download_Props) {
    return (
        <div className='Button_Subpage_Background'>
            <a href={props.Link}/>
            <div className='Button_Subpage_Header'>{props.Text}</div>
            <img src={props.FlavourImage} alt={props.FlavourImage}/>
        </div>
    );
}


export default Button_Download