import React from "react";

import './TextBox_Character.css';

interface TextBox_Character_Props {
    Header: string;
    Text: string[];
    CharacterImage: string;
}

function TextBox_Character(props:TextBox_Character_Props) {
    return (
        <header className="Paragraph-Header">
            <h1>{props.Header}</h1>
            <div className="ParagraphLayout">
                <div> 
                    {props.Text.map((text: string) => <p>{text}</p>)}
                </div>   
                <img className="OfficerPortrait" src={props.CharacterImage} alt={props.CharacterImage}/>
            </div>
        </header>
    );
}

function TextBox_Character_Alt(props:TextBox_Character_Props) {
    return (
        <header className="Paragraph-Header">
            <h1>{props.Header}</h1>
            <div className="ParagraphLayout">
            <img className="OfficerPortrait" src={props.CharacterImage} alt={props.CharacterImage}/>
                <div> 
                    {props.Text.map((text: string) => <p>{text}</p>)}
                </div>   
            </div>
        </header>
    );
}


export { TextBox_Character, TextBox_Character_Alt}

