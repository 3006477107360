import React from 'react';

import './GameTitle.css';

class GameTitle extends React.Component {
    render() 
    { 
      return <div>
        <div className="GameTitle">
            <div className='BigText'>Calamity</div>
                <div className='AtRow'>
                    <div className='FlavourLine_Left'/>
                    <div className='WeeText'>at</div>
                    <div className='FlavourLine_Right'/>
                </div>
            <div className='BigText'>Starbase:349</div>
        </div>
    </div>
    }
};

export default GameTitle