import React from 'react';
import './ErrorPage.css';

import ErrorGif from './Resources/Error.gif';
import Headerbar from '../../Components/Headerbar/Headerbar'; 

function App() {
  return (
    <div className='NoScroll'>
      <div className="FilmGrain"/>
      <Headerbar/>    
      <header className="PageContents">
        <p className="ErrorText">There appears to be a malfunction!</p>
        <img className="ErrorImage" src={ErrorGif} alt={ErrorGif}></img>
        <p className="ErrorText">We've dispatched an Engineer to take a look.</p>
      </header>
    </div>
  );
}

export default App; 
