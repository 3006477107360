import React from 'react';
import './Download.css';

import Headerbar from '../../Components/Headerbar/Headerbar';
import Button_Download from 'src/Components/Button_Download/Button_Download';
import OpsOfficer from 'src/Resources/OpsOfficer.png';
import EngineeringOfficer from 'src/Resources/EngineeringOfficer.png';

function App() {

  const paths: string[] = ["v0.1", "v0.2"]

  return (
    <div className='NoScroll'>
      <div className="FilmGrain" />
      <Headerbar />
      <header className="PageContents">
        <Button_Download Text="Playtest 2" FlavourImage={OpsOfficer} Link={"https://calamitystorage.blob.core.windows.net/game/v0.2.zip"} />
        <Button_Download Text="Playtest 1" FlavourImage={EngineeringOfficer}  Link={"https://calamitystorage.blob.core.windows.net/game/v0.1.zip"} />
      </header>
    </div>
  );
}

export default App; 
