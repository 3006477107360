import React from 'react';
import './Modding.css';

import Button_Subpage from 'src/Components/Button_Subpage/Button_SubPage';

import FlavourImage_Modding_GameObjects from 'src/Resources/FlavourImage_Modding_GameObjects.png';
import FlavourImage_Modding_Ships from 'src/Resources/TheBar.gif';

function Modding() {
  return (
    <div className='Container_Buttons'>
      <Button_Subpage Route='/Modding/GameObjects'PageName='Game Objects' FlavourImage={FlavourImage_Modding_GameObjects}/>
      <Button_Subpage Route='/Modding/Characters'PageName='Ships' FlavourImage={FlavourImage_Modding_GameObjects}/>
      <Button_Subpage Route='/Modding/Ships'PageName='Characters' FlavourImage={FlavourImage_Modding_GameObjects}/>
    </div>
  );
}

export default Modding;
