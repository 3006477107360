import React from 'react';
import './UnderConstruction.css';

import ErrorGif from './Resources/Error.gif';

function App() {
  return (
    <div className='NoScroll'>
      <header className="PageContents">
        <p className="ErrorText">This ship isn't ready for lift off just yet.</p>
        <img className="ErrorImage" src={ErrorGif} alt={ErrorGif}></img>
        <p className="ErrorText">Follow us on Twitter & Instagram to keep up with the latest news!</p>
      </header>
    </div>
  );
}

export default App; 
