import React from 'react';
import './Home.css';

import GameTitle from '../../Components/GameTitle/GameTitle';

import OpsOfficer from 'src/Resources/OpsOfficer.png';
import EngineeringOfficer from 'src/Resources/EngineeringOfficer.png';

import {TextBox_Character, TextBox_Character_Alt} from 'src/Components/TextBox_Character/TextBox_Character';

function Home() {
  return (
      <div className="PageContent">
        <header className="App-header">
          <GameTitle/>
        </header>

        <TextBox_Character CharacterImage={OpsOfficer} Header='What is Calamity at Starbase:349?'
          Text={[
            'Calamity is a multiplayer immersive sim where you and your crew run a starship together!', 
            'As an officer of the Star League it\'s your mandate to know the unknowable and Explore the unexplored, Be that as the intrepid Captain, a Tireless Medical Technican or an upstart Ensign out on your first assignment.', 
            'An episode of Calamity at Starbase:349 will play for roughly 45 minutes to an hour, your crew is given a mission and it\'s your job to work together and see it through. But can everyone be trusted?'
          ]}/>

        <TextBox_Character_Alt CharacterImage={EngineeringOfficer} Header='How does it work?'
          Text={[
            'When 349 is ready to be played, It\'ll be released here on this website for free. Simply download the game, make your character and join a server.',
            '349 has been designed from the ground up to be modded, every in game assets are scripted using Lua and are freely available in the games resources folder.',
            'We also ship the game with the full level editor allowing you to make just about any ship or space station you want.',
            'For more information please feel free to check out the modding section of this site.'
        ]}/>
     
      </div>

  );
}

export default Home;
