import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from 'src/reportWebVitals';

import Headerbar from 'src/Components/Headerbar/Headerbar';
import Home from 'src/Routes/Home/Home';
import UnderConstruction from 'src/Routes/UnderConstruction/UnderConstruction';
import Modding from 'src/Routes/Modding/Modding'
import ErrorPage from './Routes/Error/ErrorPage';
import Download from './Routes/Download/Download';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <div className="App">
  <div className="FilmGrain"/>
  <Headerbar/>    
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Download" element={<Download/>}/>
        <Route path="/Modding" element={<Modding/>}/>

        <Route path="/Modding/GameObjects" element={<UnderConstruction/>}/>
        <Route path="/Modding/Characters" element={<UnderConstruction/>}/>
        <Route path="/Modding/Ships" element={<UnderConstruction/>}/>

        <Route path="*" element={<ErrorPage/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
